import * as React from "react";
import PageWrapper from "../../components/pages/PageWrapper";
import SignCard from "../../components/pages/sign/SignCard";
import {Checkbox, Form, Input, Modal, message, Button} from "antd";
import SmsInput from "../../components/pages/sign/SmsInput";
import styled from "@emotion/styled";
import Seo from "../../components/seo"
import {useEffect, useMemo, useState} from "react";
import request from "../../utils/request";
import EmailInput from "../../components/pages/sign/EmailInput";
import auth from "../../utils/auth";
import {useDispatch} from "react-redux";
/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import {useRequest} from "ahooks";
import jsonRequest from "../../utils/request/jsonRequest";
import Serialize from "../../components/editor/serialize";

const CheckAgreement = styled.div`
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
`

const Link = styled.a`
  color: #1560FA;
  text-underline: none !important;
  text-decoration: none;
`

const SignIn = ({location}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [account, setAccount] = useState("")
  const [type, setType] = useState<'phone' | 'email'>("phone")
  const [checked, setChecked] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const {data: manual} = useRequest(() => {
    return jsonRequest(`/manual/9`).then(rsp => {
      if (rsp.success) {
        return rsp.data.data
      } else {
        message.error(rsp.message)
      }
    }).catch(console.error)
  })

  useEffect(() => {
    if (account.match(/^.*@.*$/)) {
      setType('email')
    } else {
      setType('phone')
    }
  }, [account])

  const contentInnerHtml = useMemo(() => {
    let content = [{"type":"paragraph","children":[{"text":manual?.content ?? ""}]}];
    try {
      content = JSON.parse(manual.content)
    } catch {}
    return {__html: content.map(n => Serialize(n)).join('')}
  }, [manual])

  const handleChange = (values) => {
    if ('account' in values) {
      setAccount(values.account);
    }
  }
  const handleFinish = (values: any) => {
    if (!checked) {
      message.warn("请同意 服务协议 和 隐私协议")
      return
    }
    if (submitting) {
      return
    }
    setSubmitting(true)
    const data = {...values}
    data[type] = account
    request(type == 'phone' ? "/register" : "/register/email", {
      method: 'POST',
      data,
    }).then((rsp) => {
      rsp.json().then((rspData) => {
        if (rspData.success) {
          message.success('注册成功')
          // navigate("/sign/in")
          auth.setToken(rspData.data.token)
          auth.load(dispatch).then(() => {
            auth.redirect()
          })
        } else {
          message.error(rspData.message)
        }
      }).catch(console.error)
    }).catch(console.error).finally(() => {
      setSubmitting(false)
    })
  }
  return (<>
    <PageWrapper>
      <SignCard
        title='注册账号'
        buttonText="登录"
        location={location}
        inputs={(
          <Form
            onValuesChange={handleChange}
            onFinish={handleFinish}
            {...{form}}
          >
            <Form.Item name='account' required={false} rules={[{required: true, message: '请输入手机或邮箱'}]}>
              <Input size="large" placeholder="请输入手机号或邮箱" />
            </Form.Item>
            <Form.Item name='password' required={false} rules={[{required: true, message: '请输入密码'}]}>
              <Input.Password size="large" placeholder="请输入密码" />
            </Form.Item>
            <Form.Item name='code' required={false} rules={[{required: true, message: type == 'phone' ? '请输入短信验证码' : '请输入验证码'}]}>
              {type == 'phone' && (
                <SmsInput phone={account} />
              ) || (
                <EmailInput email={account}/>
              )}
            </Form.Item>
          </Form>
        )}
        append={(
          <CheckAgreement>
            <Checkbox checked={checked} onChange={() => setChecked(!checked)}/>
            {/*我已阅读并同意《<Link href='/about?type=agreement&id=9' target='_blank'>服务协议</Link>》和《<Link href='/about?type=agreement&id=8' target='_blank'>隐私协议</Link>》*/}
            我已阅读并同意《<Link href='#' onClick={() => setShowModal(true)}>服务协议</Link>》和《<Link href='#' onClick={() => setShowModal(true)}>隐私协议</Link>》
          </CheckAgreement>
        )}
        onSubmit={form.submit}
      >
      </SignCard>
      <Modal
        title='我已阅读并同意《服务协议》和《隐私协议》'
        width={780}
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={(<>
          <Button
            type="primary"
            onClick={() => {
              setChecked(true)
              setShowModal(false)
            }}
          >我已阅读并同意</Button>
        </>)}
        // @ts-ignore
        css={css`
          .ant-modal-header {
            padding: 24px 32px;
          }
          .ant-modal-close-x {
            margin: 10px 16px;
          }
          .ant-modal-footer {
            padding: 24px 32px;
          }
          .ant-modal-body {
            padding: 0 32px;
          }
          .content {
            padding: 12px;
            background: rgba(0,0,0,0.04);
            border-radius: 4px 4px 4px 4px;
          }
        `}
      >
        <div className='content' dangerouslySetInnerHTML={contentInnerHtml} >
          {/*服务协议1.总则平台与用户共同确认: 1.1 用户在使用平台服务前，已仔细阅读《用户服务协议》 (以下简称“本协议”)的全部内容，对于本协议中的加重字体条款已重点阅读、理解。1.2 用户同意本协议的全部条款并按照页面上的提示完成全部的注册登录程序。用户完成注册登录程序后，即表示用户与平台达成以下协议，完全接受本协议项下的全部条款，本协议即在用户与平台之间产生法律效力，对双方均具有法律约束力。1.3 用户注册登录成功后，将获得自行注册登录的用户账号和自行设定的密码在平台的使用权限，该用户账号和密码由用户负责保管;用户对以其用户账号进行的所有活动和事件负法律责任。1.4 用户服务协议、各单项服务条款、全部活动规则及公告、声明等，均为本协议不可分割的组成部分 (以下统称“协议”)。平台有权对协议进行不定期修订、更新。修改后的协议一经公布即有效替代原有协议，新协议公布时会以适当方式通知用户，用户可随时查询最新协议。1.4 用户服务协议、各单项服务条款，全部活动规则及公告，声明等，均为本协议不可分割的组成 我已阅读并同意*/}
        </div>
      </Modal>
    </PageWrapper>
  </>)
}

export default SignIn

export const Head = () => <Seo title='GitWork-新用户注册'/>
